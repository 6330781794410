import { Box, Stack, Typography, styled } from '@mui/material';
import AlertCircle from 'component/new_design/icons/AlertCircle.svg?react';
import CheckCircle from 'component/new_design/icons/CheckCircle.svg?react';
import { useTranslation } from 'react-i18next';
import { cssVar } from 'utils/css';

interface Props {
  readonly number: number;
  readonly title: string;
  readonly description: string;
  readonly status: 'default' | 'active' | 'success' | 'error' | 'warning';
}

const Wrapper = styled(Box)<{ status: Props['status'] }>(({ theme, status }) => {
  let backgroundColor, stepColor, iconColor, titleColor, descriptionColor;
  switch (status) {
    case 'default':
      backgroundColor = theme.palette.greys[50];
      stepColor = theme.palette.greys[500];
      iconColor = theme.palette.greys[900];
      titleColor = theme.palette.greys[900];
      descriptionColor = theme.palette.greys[500];
      break;
    case 'active':
      backgroundColor = theme.palette.greys[900];
      stepColor = theme.palette.greys[100];
      iconColor = theme.palette.greys[100];
      titleColor = theme.palette.common.white;
      descriptionColor = theme.palette.greys[200];
      break;
    case 'success':
      backgroundColor = theme.palette.greens[100];
      stepColor = theme.palette.greens[700];
      iconColor = theme.palette.greens[700];
      titleColor = theme.palette.greens[900];
      descriptionColor = theme.palette.greens[800];
      break;
    case 'error':
      backgroundColor = theme.palette.reds[100];
      stepColor = theme.palette.reds[700];
      iconColor = theme.palette.reds[700];
      titleColor = theme.palette.reds[900];
      descriptionColor = theme.palette.reds[800];
      break;
    case 'warning':
      backgroundColor = theme.palette.oranges[100];
      stepColor = theme.palette.oranges[700];
      iconColor = theme.palette.oranges[700];
      titleColor = theme.palette.oranges[900];
      descriptionColor = theme.palette.oranges[800];
      break;
  }

  return {
    borderRadius: cssVar('--border-radius-m'),
    backgroundColor,
    color: descriptionColor,
    padding: theme.spacing(3),
    gap: theme.spacing(1),
    '& .step-step': {
      color: stepColor,
      textTransform: 'uppercase',
      fontWeight: 600,
      fontSize: '10px',
    },
    '& .step-icon': {
      color: stepColor,
      width: '16px',
      height: '16px',
    },
    '& .step-title': {
      color: titleColor,
      fontWeight: 600,
    },
  };
});

const getIcon = (status: Props['status']) => {
  switch (status) {
    case 'default':
    case 'active':
      return null;
    case 'success':
      return CheckCircle;
    case 'error':
    case 'warning':
      return AlertCircle;
  }
};

export const Step = ({ number, title, description, status }: Props) => {
  const { t } = useTranslation();

  const Icon = getIcon(status);

  return (
    <Wrapper status={status}>
      <Stack justifyContent="space-between" direction="row">
        <Box className="step-step">{t('step_number', { number })}</Box>
        <Box className="step-icon">{Icon ? <Icon /> : null}</Box>
      </Stack>
      <Typography variant="body2" className="step-title">
        {title}
      </Typography>
      <Typography variant="body2">{description}</Typography>
    </Wrapper>
  );
};
