import { Box, Typography, Stack, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';
import ToggleSettings from 'component/view/SiteDetails/Advanced/components/SiteSettings/ToggleSetting';
import { InfoTooltip } from 'component/new_design/base/InfoTooltip';
import { TextField } from 'component/new_design/base/forms/TextField';
import { SelectField } from 'component/new_design/base/forms/SelectField';

export interface WordpressInfoFormValue {
  username: string;
  password: string;
  email: string;
  multisite: boolean;
  preferredLanguage: string;
  atarim: boolean;
  wooCommerce: boolean;
  wpRecommendedPlugins: boolean;
}

interface Props {
  readonly onComplete: (data: WordpressInfoFormValue) => void;
  readonly formId: string;
  readonly hasTemplateId: boolean;
}

export const WordpressInformationStep = ({ onComplete, formId, hasTemplateId }: Props) => {
  const { t } = useTranslation();

  const methods = useForm<WordpressInfoFormValue>({
    defaultValues: {
      username: '',
      password: '',
      email: '',
      preferredLanguage: 'english',
      atarim: false,
      multisite: false,
      wooCommerce: false,
      wpRecommendedPlugins: hasTemplateId,
    },
    mode: 'onChange',
  });
  const { handleSubmit, setValue, getValues } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onComplete)} id={formId}>
        <Box sx={{ display: 'grid', gridTemplateColumns: 'auto auto', gap: 4 }}>
          <TextField
            name="username"
            label={t('wordpress_username')}
            placeholder={t('wordpress_username_placeholder')}
            fullWidth
            rules={{
              required: true,
            }}
          />
          <TextField
            name="password"
            label={t('wordpress_password')}
            fullWidth
            type="password"
            rules={{
              required: true,
            }}
          />
          <TextField
            name="email"
            label={t('wordpress_email')}
            fullWidth
            rules={{
              validate: email => isEmail(email) || t('invalid_email'),
            }}
          />
          <SelectField
            name="preferredLanguage"
            label={t('language')}
            fullWidth
            options={[
              {
                value: 'english',
                label: t('english'),
              },
            ]}
          />
        </Box>
        <Box mt={4}>
          <Typography>
            <strong>{t('additional_options')}</strong>
            {hasTemplateId ? (
              <InfoTooltip>{t('create_site_using_a_template_no_options_warning')}</InfoTooltip>
            ) : null}
          </Typography>
          {t('additional_options_details')}
        </Box>
        <Stack divider={<Divider flexItem />} gap={1} mt={4}>
          <Stack direction="row" justifyContent="space-between">
            <Typography>{t('multisite_support')}</Typography>
            <ToggleSettings
              disabled={hasTemplateId}
              onChange={async value => {
                setValue('multisite', value.value === 1);
                return;
              }}
              name="multisite_support"
              value={getValues().multisite}
            />
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography>{t('woocommerce')}</Typography>
            <ToggleSettings
              disabled={hasTemplateId}
              onChange={async value => {
                setValue('wooCommerce', value.value === 1);
                return;
              }}
              name="woo_commerce"
              value={getValues().wooCommerce}
            />
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography>{t('atarim_collaborate')}</Typography>
            <ToggleSettings
              disabled={hasTemplateId}
              onChange={async value => {
                setValue('atarim', value.value === 1);
                return;
              }}
              name="atarim"
              value={getValues().atarim}
            />
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography>
              {t('wp_recommended_plugins')}
              {!hasTemplateId ? (
                <InfoTooltip>
                  <Typography>{t('wp_recommended_plugins_help')}</Typography>
                  <ul style={{ listStyle: 'inside' }}>
                    <li>{t('wp_recommended_plugins_help_item_1')}</li>
                    <li>{t('wp_recommended_plugins_help_item_2')}</li>
                    <li>{t('wp_recommended_plugins_help_item_3')}</li>
                    <li>{t('wp_recommended_plugins_help_item_4')}</li>
                    <li>{t('wp_recommended_plugins_help_item_5')}</li>
                  </ul>
                </InfoTooltip>
              ) : null}
            </Typography>
            <ToggleSettings
              disabled={hasTemplateId}
              onChange={async value => {
                setValue('wpRecommendedPlugins', value.value === 1);
                return;
              }}
              name="wp_recommended_plugins"
              value={getValues().wpRecommendedPlugins}
            />
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};
