import { Button } from '@mui/material';
import { SiteListItem } from 'api/site';
import { linkHelper } from 'linkHelper';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ChevronRight from 'component/new_design/icons/ChevronRight.svg?react';

interface Props {
  readonly site: SiteListItem;
}

export const ManageCell = ({ site }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const path = linkHelper.newDesign.sites.siteDetails.overview.getLink({ siteId: String(site.id) });

  return (
    <Button
      onClick={() => navigate(path)}
      variant="text"
      color="primary"
      endIcon={<ChevronRight />}
    >
      {t('manage')}
    </Button>
  );
};
