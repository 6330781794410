import { Button } from '@mui/material';
import { SiteListItem } from 'api/site';
import { useExternalLink } from 'component/hooks/useExternalLink';
import { IconWrapper } from 'component/new_design/base/IconWrapper';
import Atarim from 'component/new_design/icons/Atarim.svg?react';
import ChevronRight from 'component/new_design/icons/ChevronRight.svg?react';
import { useTranslation } from 'react-i18next';

interface Props {
  readonly site: SiteListItem;
}

export const AtarimCell = ({ site }: Props) => {
  const { t } = useTranslation();
  const { openExternalLink } = useExternalLink();

  return (
    <Button
      onClick={() =>
        openExternalLink({
          url: `https://app.atarim.io/fetching/?_from=rocket&url=https://${site.domain}`,
        })
      }
      variant="text"
      endIcon={
        <IconWrapper size="xs" color="greys.500">
          <ChevronRight />
        </IconWrapper>
      }
      title={t('atarim')}
    >
      <IconWrapper size="m" color="greys.900">
        <Atarim />
      </IconWrapper>
    </Button>
  );
};
