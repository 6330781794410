import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { SiteListItem } from 'api/site';
import GitFork from 'component/new_design/icons/GitFork.svg?react';
import { useTranslation } from 'react-i18next';

interface Props {
  readonly site: SiteListItem;
}

export const DomainCell = ({ site }: Props) => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" gap={2} alignItems="center">
      <Typography variant="body2" fontWeight={600}>
        {site.domain}
      </Typography>
      <Tooltip title={t('staging')} placement="top">
        <Box color="greys.400" sx={{ display: 'grid', placeItems: 'center' }}>
          {site.staging ? <GitFork /> : null}
        </Box>
      </Tooltip>
    </Stack>
  );
};
