import { Button } from '@mui/material';
import { SiteListItem } from 'api/site';
import { useExternalLink } from 'component/hooks/useExternalLink';
import { SiteLockedPopover } from 'component/partial/SiteLockedPopover';
import Wordpress from 'component/new_design/icons/Wp.svg?react';
import ChevronRight from 'component/new_design/icons/ChevronRight.svg?react';
import { getSiteLockedStatus } from 'utils/site';
import { IconWrapper } from 'component/new_design/base/IconWrapper';
import { useTranslation } from 'react-i18next';

interface Props {
  readonly site: SiteListItem;
}

export const WPAdminCell = ({ site }: Props) => {
  const { t } = useTranslation();
  const { openExternalLink } = useExternalLink();
  const isSiteStatic = site.site_type === 1;
  const isSiteLocked = getSiteLockedStatus(site);

  return isSiteStatic ? (
    '- '
  ) : (
    <SiteLockedPopover isSiteLocked={isSiteLocked}>
      <Button
        onClick={() =>
          openExternalLink({
            iframeUrl: `/manage/${site.id}/wordpress?token=${window.sessionStorage.getItem('user_session')}`,
            url: `/manage/${site.id}/wordpress}`,
          })
        }
        disabled={isSiteLocked}
        variant="text"
        endIcon={
          <IconWrapper size="xs" color="greys.500">
            <ChevronRight />
          </IconWrapper>
        }
        title={t('wp_admin')}
      >
        <IconWrapper size="m" color="greys.900">
          <Wordpress />
        </IconWrapper>
      </Button>
    </SiteLockedPopover>
  );
};
