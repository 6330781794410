import { Box, Link, Stack, Typography } from '@mui/material';
import { useLocations, Location } from 'api/locations';
import { useSiteTemplates } from 'api/site';
import { linkHelper } from 'linkHelper';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SelectOption } from 'component/new_design/base/forms/Select';
import { TextField } from 'component/new_design/base/forms/TextField';
import { SelectField } from 'component/new_design/base/forms/SelectField';
import { Flag } from 'component/new_design/base/Flag';

export interface SiteDetailsFormValue {
  readonly siteName: string;
  readonly location: string | undefined;
  readonly templateId: string | undefined;
  readonly isRestrictedLocation: boolean;
}

interface Props {
  readonly onComplete: (data: SiteDetailsFormValue) => void;
  readonly formId: string;
}

export const SiteDetailsStep = ({ onComplete, formId }: Props) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      siteName: '',
      location: undefined,
      templateId: searchParams.get('templateId') || '',
    },
    mode: 'onChange',
  });

  const { data: siteTemplatesData, isLoading } = useSiteTemplates();

  const { data } = useLocations();
  const locations = data?.data.result.locations ?? [];
  const enterpriseLocations = data?.data.result.restricted_locations ?? [];
  const enterpriseLocationIds = enterpriseLocations.map(location => String(location.id));

  const createLocationOption = ({ id, location }: Location) => {
    const locationSplit = location.split(' - ');
    return {
      value: String(id),
      label: (
        <Stack direction="row" alignItems="center" gap={2}>
          <Flag code={locationSplit[0]} size="s" />
          <div>{location}</div>
        </Stack>
      ),
    };
  };

  let locationOptions: SelectOption<string>[] = [];

  if (enterpriseLocations?.length) {
    locationOptions = [
      { type: 'subheader', label: t('enterprise_locations') },
      ...enterpriseLocations.map(createLocationOption),
      { type: 'subheader', label: t('standard_locations') },
      ...locations.map(createLocationOption),
    ];
  } else {
    locationOptions = locations.map(createLocationOption) ?? [];
  }

  const templateOptions: SelectOption<string>[] =
    siteTemplatesData?.data.result?.site_templates?.map(template => ({
      value: template.template_id ?? '',
      label: template.name ?? '',
    })) ?? [];

  const noTemplatesAvailable = !isLoading && !templateOptions.length;

  const handleSubmit = (values: Omit<SiteDetailsFormValue, 'isRestrictedLocation'>) => {
    onComplete({
      ...values,
      isRestrictedLocation: enterpriseLocationIds.includes(values.location!),
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)} id={formId}>
        <Stack gap={4} maxWidth="320px">
          <TextField
            name="siteName"
            label={t('site_name_label')}
            placeholder={t('site_name_label_placeholder')}
            fullWidth
            rules={{
              required: true,
            }}
            helperText={t('site_name_label_description')}
          />
          <SelectField
            name="location"
            label={t('select_location')}
            fullWidth
            enableEmptyOption
            emptyOptionLabel={t('choose_location')}
            options={locationOptions}
            rules={{ required: true }}
          />
          <SelectField
            name="templateId"
            label={t('build_from_template')}
            fullWidth
            options={templateOptions}
            enableEmptyOption
            emptyOptionLabel={t('select_template')}
            loading={isLoading}
            disabled={noTemplatesAvailable}
          />
          {!!noTemplatesAvailable && (
            <Box marginTop={2}>
              <Typography variant="body1" fontWeight="bold">
                {t('create_template_site_create_title')}
              </Typography>
              <Typography variant="body1" color="gray">
                <Trans
                  i18nKey="create_template_site_create_description"
                  components={{
                    siteTemplatesLink: (
                      <Link
                        onClick={() => {
                          navigate(linkHelper.sites.templates.getLink());
                        }}
                      />
                    ),
                  }}
                />
              </Typography>
            </Box>
          )}
        </Stack>
      </form>
    </FormProvider>
  );
};
