import { useContext, useState } from 'react';
import { PostMessageContext } from 'component/postMessage/PostMessageContext';
import { SiteListItem, useSitesNew, useSitesUsersNew } from 'api/site';
import { useProfile } from 'api/profile';
import { formatDataSize } from 'utils/number';
import { useTranslation } from 'react-i18next';
import { getIsWpSiteControl } from 'utils';
import { TableColumnType } from 'component/base/Table/types';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { Table } from 'component/base/Table';
import { LocationCell } from '../common/LocationCell';
import { AtarimCell } from './AtarimCell';
import { WPAdminCell } from './WPAdminCell';
import { ManageCell } from './ManageCell';
import { DomainCell } from './DomainCell';
import { EmptyState } from 'component/new_design/base/EmptyState';
import Monitor from 'component/new_design/icons/Monitor.svg?react';
import Plus from 'component/new_design/icons/Plus.svg?react';
import { Button } from '@mui/material';
import { CreateSiteModal } from '../../CreateSite/CreateSiteModal';

interface SitesTableProps {
  readonly enablePagination?: boolean;
  readonly enableSearch?: boolean;
  readonly children?: React.ReactNode;
}

const useIsAtarimEnabled = () => {
  const { getSdk } = useContext(PostMessageContext);
  const { data: profileData } = useProfile();

  const packagePlan = profileData?.data.result?.package?.plan;

  return Boolean(getSdk() === null && packagePlan && packagePlan.toLowerCase() !== 'starter');
};

export function SitesTable({ enablePagination, enableSearch, children }: SitesTableProps) {
  const { t } = useTranslation();
  const isWpSiteControl = getIsWpSiteControl();
  const showAtarim = useIsAtarimEnabled();

  const tableState = usePaginatedListState();

  const sitesNew = useSitesNew(tableState, { enabled: !isWpSiteControl });
  const sitesUserNew = useSitesUsersNew(tableState, { enabled: isWpSiteControl });

  const { data, isLoading } = isWpSiteControl ? sitesUserNew : sitesNew;

  const [isCreateSiteOpen, setIsCreateSiteOpen] = useState(false);

  const columns: (TableColumnType<SiteListItem> | null)[] = [
    {
      label: t('domain'),
      key: 'domain',
      renderValue: site => <DomainCell site={site} />,
      sortable: true,
      width: 3,
    },
    {
      label: t('label'),
      key: 'label',
      sortable: true,
      width: 2,
    },
    {
      label: t('disk_usage'),
      renderValue: site => formatDataSize(site.disk_usage),
      key: 'disk_usage',
      sortable: true,
    },
    {
      label: t('location'),
      renderValue: site => {
        const location = site.location?.split(' - ') ?? ['', 'Unknown'];
        return <LocationCell code={location[0]} label={location.join(' \u00b7 ')} />;
      },
      width: 2,
    },
    showAtarim
      ? {
          label: t('atarim'),
          renderValue: site => <AtarimCell site={site} />,
        }
      : null,
    {
      label: t('wp_admin'),
      renderValue: site => <WPAdminCell site={site} />,
    },
    {
      label: t('action'),
      align: 'right',
      renderValue: site => <ManageCell site={site} />,
      minWidth: '64px',
      width: 0,
    },
  ];

  return (
    <>
      <Table
        title={t('sites')}
        columns={columns}
        data={data?.data.result || []}
        totalRowCount={data?.data.metadata?.total || 0}
        isLoading={isLoading}
        state={tableState}
        searchPlaceholder={t('search_sites_label')}
        enableSearch={enableSearch}
        enablePagination={enablePagination}
        emptyState={
          <EmptyState
            icon={<Monitor />}
            title={t('no_sites_yet')}
            description={t('to_add_data_create_a_new_site')}
            action={
              <Button
                onClick={() => setIsCreateSiteOpen(true)}
                size="medium"
                variant="contained"
                color="primary"
                startIcon={<Plus />}
              >
                {t('create_site')}
              </Button>
            }
          />
        }
      >
        {children}
      </Table>

      {isCreateSiteOpen ? <CreateSiteModal onCancel={() => setIsCreateSiteOpen(false)} /> : null}
    </>
  );
}
